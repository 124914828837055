footer {
    h2{
        font-family: $font2;
        text-align: left;
        font-size: 24px;
        margin-bottom: 30px;
    }

    .btn {
        float: left;
        background: $color4;
        border-radius: 5px;
        padding: 10px 20px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 14px;
    }

    .wrapper-blue {
        padding: 90px 0 30px 0;

        .logo {
            margin-top: 15px;
            width: 50%;
        }

        .brands {
            padding-top: 60px;
            

            span {
                font-size: 28px;

                &:not(:last-of-type) {
                    margin-right: 30px;
                }
            }
        }

        .footnote{
            padding-top: 60px;
        }

        ul.social {
            list-style: none;
            padding: 0;
            margin: 30px 0 30px 0;
            text-align: left;

            li {
                display: inline-block;
                margin-left: 5px;

                a {
                    color: #FFF;
                    text-decoration: none;

                    span {
                        width: 48px;
                        height: 48px;
                        line-height: 48px;
                        border-radius: 24px;
                        display: inline-block;
                        font-size: 34px;
                        background: $color1;
                        color: #FFF;
                        text-align: center;
                    }
                }
            }
        }

        a {
            color: #FFF;
            text-decoration: none;
        }

        small {
            font-size: 12px;
        }

        .middle{
            font-size: 16px;

            ul {
                list-style: none;
                margin: 28px 0 0 0;
                padding: 0;
    
                li {
                    position: relative;
                    display: block;
                    text-align: left;
                    padding-left: 20px;

                    a {
                        color: #fff;
                        text-decoration: none;
                    }

                    &:before {
                        position: absolute;
                        left: 0;
                        top: 4px;
                        content: '\e91a';
                        font-family: 'icomoon';
                        color: rgba($color: #fff, $alpha: 0.4);
                        font-size: 12px;
                    }
                }
            }
        }

        address {
            font-style: normal;
            font-size: 16px;
    
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
    
                li {
                    display: block;
                    text-align: left;
                    a {
                        color: #fff;
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {

}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
    footer {
        .social {
            ul.social {
                float: right;
            }

            .btn {
                float: right;
            }
        }
    }
}

// *****************************************************************
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
    footer {
        h2 {
            margin-bottom: 80px;
        }
        address {
            ul {
                li {
                    display: inline-block;
                    margin-right: 20px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}