#news {
	#omschrijving {
		img {
			display: block;
			width: 100%;
			border: 1px solid #EEE;
			margin-bottom: 15px;
		}
	}
}

@media (min-width: $screen-sm-min) {
	#news {
		#omschrijving {
			img {
				float: left;
				width: 25%;
				margin-right: 30px;

			}
		}
	}
}