#overons {
	padding: 30px 0;

	h1 {
		color: $color1;
		text-transform: unset;
	}
	
	.employees {
		margin-top: 30px;

		img{
			max-width: 100%;
		}

		.employee {
			margin-bottom: 30px;

			.row{
				border-radius: 5px;
				overflow: hidden;
			}
			
			&:last-child {
				margin-bottom: 0;
			}
	
			.image {
				min-height: 300px;
				background-size: cover !important;
			}
			.content {
				position: relative;
				padding: 60px 15px 15px;
				background: #EDEDED;
	
				.linkedin {
					position: absolute;
					top: 0;
					right: 15px;
					background: $color1;
					color: #fff;
					text-decoration: none;
					padding: 3px 10px 0;
					font-size: 30px;
					border-radius: 0px 0px 5px 5px;
				}
	
				p {
					strong {
						color: $color1;
	
						span {
							color: $color2;
						}
					}
	
					&:last-child {
						margin-bottom: 0;
					}
				}
	
				.leesmeer {
					display: block;
					text-decoration: none;
					margin-top: 15px;
	
					.icon {
						display: inline-block;
						margin-left: 5px;
					}
				}
			}
	
			.modal {
				.modal-header {
					h4 {
						display: inline-block;
					}
					.close {
						float: right;
						text-decoration: none;
						color: $color2;
						font-size: 20px;
					}
				}
			}
		}
	}
}

@media (min-width: $screen-sm-min) {
	#overons {
		.employees {
			.employee {
				width: calc(50% - 7.5px);
	
				&:nth-of-type(odd) {
					margin-right: 7.5px;
				}
				&:nth-of-type(even) {
					margin-left: 7.5px;
				}
			}
		}
	}
}

@media (min-width: $screen-md-min) {
	#overons {
		padding: 50px 0;

		.employees {
			.employee {
				width: calc(41.66% - 7.5px);
			}
		}
	}
}

@media (min-width: $screen-lg-min) {
	#overons {
		padding: 100px 0;

		.employees {
			.employee {
				width: calc(50% - 7.5px);
			}
		}
	}
}