#details {
    padding-bottom: 0;
    .modal {
        .close {
            position: absolute;
            right: 15px;
            top: 15px;
            background: none;
            border: none;
        }
    }
    .wrapper-line {
        border-bottom: 1px solid #d8d8d8;
    }
    h1 {
        margin-top: 25px;
        span {
            display: block;
            color: #999;
        }
    }
    h2 {
        margin: 0;
        margin-top: 25px;
    }
    h3.open-huis {
        color: $color1;
        margin: 20px 0 0 0;
    }
    #details-slider,
    #details-thumbs {
        .slide {
            img {
                display: block;
                width: 100%;
            }
        }
    }
    #details-slider {
        img {
            margin-bottom: 15px;
            border-radius: 5px;
        }
    }
    #details-thumbs {
        img {
            margin-bottom: 15px;
            border-radius: 5px;
        }
    }
    #tabs {
        height: 90px;
        padding:0;
        transition: all 300ms ease;
        &.fixed {
            position: fixed;
            left: 0;
            width: 100%;
            z-index: 1;
            background: $color1;
            a {
                color: #fff;
                &.btn {
                    background: #fff;
                    color: #000;
                }
                span {
                    color: #fff;
                }
            }
        }
    }
    ul.dropdown-menu {
        list-style-type: none;
        display: block;
        padding: 0;
        -webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        li {
            display: block;
            min-width: 100%;
            padding: 10px 0px;
            a {
                display: block;
                text-align: center;
                width: 100%;
                background-color: white;
                color: #000 !important;
                padding: 5px 15px;
                text-decoration: none;
                white-space: nowrap;
                font-weight: bold;
                font-size: 16px;
                text-transform: uppercase;
                text-decoration: none;
                span {
                    display: none;
                }
            }
        }
    }
    ul.detail_buttons {
        display: none;
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: center;
        li {
            display: inline-block;
            margin-top: 15px;
            a {
                display: block;
                //background: $color1;
                //border: 1px solid $color1; 
                color: #000;
                padding: 0 15px;
                text-decoration: none;
                white-space: nowrap;
                font-weight: bold;
                font-size: 16px;
                text-transform: uppercase;
                &.btn {
                    color: #fff;
                    padding: 15px 15px;
                    font-size: 16px;
                }
                span {
                    color: $color1;
                    margin-right: 10px;
                    font-size: 18px;
                    vertical-align: -1px;
                }
            }
            &.active a {
                color: $color1;
                &.btn {
                    background: #fff;
                }
            }
            &:first-child {
                margin-left: 0;
                a {
                    span {
                        vertical-align: -3px;
                    }
                }
            }
        }
    }
    .detail_content {
        h2 {
            text-align: left;
            margin-bottom: 30px;
        }
        h3 {
            font-weight: bold;
            color: #000;
            font-size: 16px;
            margin: 0;
            line-height: 40px;
        }
        .foto {
            a {
                position: relative;
                display: block;
                img {
                    display: block;
                    width: 100%;
                    margin: 15px 0;
                    border-radius: 5px;
                }
                &:hover {
                    &:after {
                        font-family: 'icomoon' !important;
                        content: "\e906";
                        display: block;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        margin: -50px 0 0 -50px;
                        width: 100px;
                        height: 100px;
                        line-height: 100px;
                        text-align: center;
                        font-size: 38px;
                        color: rgba(255, 255, 255, 0.8);
                    }
                }
            }
        }
        dl {
            width: 100%;
            margin: 0 0 40px 0;
            border-top: 1px solid #e6e6e6;
            dd,
            dt {
                display: inline-block;
                border-bottom: 1px solid #e6e6e6;
                width: 50%;
                line-height: 40px;
                margin: 0px;
            }
        }
        #gmap_streetview,
        #gmap_locatie,
        .gmap_wrapper iframe {
            width: 100%;
            height: 400px;
            border: unset;
        }
        a.meer {
            color: $color1;
            font-weight: bold;
            text-decoration: none;
        }
        .description_short+.description_long {
            display: none;
        }
        textarea {
            height: 96px;
        }
        .object-overig {
            position: relative;
            width: 100%;
            padding-bottom: 56%;
            margin-bottom: 30px;
            div,
            video,
            iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 5px;
                overflow: hidden;
                border: none;
            }
        }
    }
}

.totop {
    display: block;
    border: 1px solid #CCC;
    position: fixed;
    right: 15px;
    bottom: 15px;
    padding: 10px 15px 5px 15px;
    text-decoration: none;
    background: #FFF;
    color: #000;
    &:hover {
        text-decoration: none;
        background: #EEE;
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 
@media (min-width: $screen-sm-min) {
    #details {
        #ObjectDetailsDropdown {
            display: none;
        }
        ul.detail_buttons {
            display: block;
        }
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 
@media (min-width: $screen-md-min) {}

// ***************************************************************** 
// LG
// ***************************************************************** 
@media (min-width: $screen-lg-min) {}