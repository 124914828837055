* {
    box-sizing: border-box;
}

html{
    scroll-behavior: smooth;
}

body {
    font-family: $font1;
    font-size: 17px;
    line-height: 1.8;
    // letter-spacing: 1px;
    overflow-x: hidden;
    color: #666;

    .container-fluid {
        margin: 0 auto;
        // min-width: 320px;
        max-width: 1440px;
    }
}

h1, h2, .h2, h3 {
    font-size: 28px;
    font-weight: 700;
    margin: 0 0 30px 0;
    color: $color1;
    line-height: 1.2;
    text-transform: uppercase;
}

h1 {
    font-weight: 700;
}

h2,
.h2 {
    font-size: 24px;
}

h3 {
    font-size: 20px;
    margin: 0 0 15px 0;
}

h4 {
    font-size: 18px;
    margin: 0 0 15px 0;
}

a {
    color: $color1;
    text-decoration: underline;

    &:hover {
        text-decoration: none;
    }
}

.clearfix {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}

.d-none{
    display: none!important;
}

.wrapper,
.search-wrapper,
.header-wrapper {
    padding: 30px 0;

    &.wrapper-lightgray {
        background: #f8f8f8;
    }

    &.wrapper-gray {
        background: #ededed;
    }

    &.wrapper-black {
        background: #000;
        color: #FFF;
    }

    &.wrapper-blue {
        background: $color1;
        color: #FFF;

        h2 {
            color: #FFF;
        }
    }
}

p {
    margin: 0 0 30px 0;

    &.intro {
        font-size: 17px;
        line-height: 1.8;

    }
}

figure {
    margin: 0;
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.text-left,
.text-xs-left {
    text-align: left !important;
}

.text-right,
.text-xs-right {
    text-align: right !important;
}

.text-center,
.text-xs-center {
    text-align: center !important;
}

.no-margin-bottom {
    margin-bottom: 0px !important;
}

.no-padding-bottom {
    padding-bottom: 0px !important;
}

.with-padding-bottom {
    padding-bottom: 30px;
}

.with-padding-top {
    padding-top: 30px;
}

blockquote {
    background: #F5F5F5;
    padding: 20px;
    margin: 0 0 30px 0;
    border-radius: 5px;
    border-left: 5px solid $color1;

    p:last-child {
        margin-bottom: 0;
    }
}

.box_wrapper {
    position: relative;
    border: 1px solid #4f4f4f;
    padding: 30px;
    min-height: 350px;
    margin: 15px 0 45px 0;

    ul {
        list-style: none;
        padding: 0;
        margin: 30px 0 0 15px;

        li {
            background: url('/img/checked.svg') 0 9px no-repeat;
            background-size: 15px;
            padding: 5px 0 5px 30px;
        }
    }

    p {
        position: absolute;
        bottom: 0;
        left: 30px;
    }
}

.slick-dots {
    position: absolute;
    left: 0px;
    bottom: -30px;
    width: 100%;
    text-align: center;
    list-style: none;
    padding: 0px;
    margin: 0px;

    li {
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #CCC;
        margin: 0px 3px 5px 3px;

        &.slick-active {
            background: #666;
        }

        button {
            display: none;
        }
    }
}

.shape {
    background: #EDEDED url("/img/shape.svg") no-repeat right;
    background-size: contain;
    position: fixed;
    left: 10px;
    right: 10px;
    bottom: 20px;
    text-align: center;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: 1px solid rgba(124, 125, 127, 0.2);

    .text {
        margin: 40px 0;

        .br {
            display: none;
        }

        h2 {
            color: #0D4580;
            font-size: 22px;
            text-transform: unset;
            font-family: Michroma, sans-serif;
            margin: 0 10px 10px;
            white-space: nowrap;
            letter-spacing: 0px;
            font-weight: 300;
        }

        p {
            line-height: 30px;
            font-size: 16px;
            color: rgba(35, 35, 35, 1);
            margin-bottom: 0;

            span {
                border-bottom: 1px solid rgba(13, 69, 128, 1);;
            }
        }
    }

    .x {
        position: absolute;
        top: 5px;
        right: 20px;
        text-decoration: none;

        i {
            font-size: 30px;
            color: rgba(124, 125, 127, 0.5);
        }
    }

    &.disable {
        display: none;
    }
}

.card {
    background: #FFF;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

    &.card-blue {
        background: $color1;
        
        h2, label {
            color: #FFF;
        }
    }
}

// *****************************************************************
// SM
// *****************************************************************

@media (min-width: $screen-sm-min) {
    .text-sm-left {
        text-align: left !important;
    }

    .shape {
        .text {
            .br {
                display: block;
            }
        }
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

// *****************************************************************
// MD
// *****************************************************************

@media (min-width: $screen-md-min) {

    .wrapper {
        padding: 50px 0;
    }

    .header-wrapper {
        h1, h2 {
            margin: 0;
        }
    }

    .shape {
        left: 30px;
        right: unset;
        bottom: 30px;

        .text {
            h2 {
                margin: 0 60px 10px;
                font-size: 30px;
            }

            p {
                font-size: 18px;
            }
        }
    }

    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }

    .with-padding-bottom {
        padding-bottom: 45px;
    }

    .with-padding-top {
        padding-top: 45px;
    }

    .card {
        padding: 30px;
    }
}

// *****************************************************************
// LG
// *****************************************************************

@media (min-width: $screen-lg-min) {

    .wrapper {
        padding: 80px 0;
    }

    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }

    .with-padding-bottom {
        padding-bottom: 60px;
    }

    .with-padding-top {
        padding-top: 60px;
    }

    .card {
        padding: 40px;
    }
}
