#aanbod {

    .less-padding{
       padding-top: 40px;
    }

    &.goesemeer{

        h1{
            margin-bottom: 0;
            flex-shrink:0;
        }

        .top{
            display: flex;
            gap: 20px;
            align-items: center;
            margin-bottom:20px;
        }

        #goesemeerAanbod{
            scroll-margin-top: 100px;
        }
    }



    .sort {
        display: inline;
        line-height: 24px;
        padding-bottom: 20px;

        .sort_wrapper {
            display: inline-block;

            ul {
                display: inline-block;
                padding-left: 20px;
                padding-right: 20px;
                margin: 0px;

                li {
                    display: inline-block;

                    a {
                        text-decoration: none;
                        line-height: 24px;
                    }

                    &.active a {
                        font-weight: 700;
                    }

                    &:not(:last-child):after {
                        content: " / ";
                    }
                }
            }
        }

        span {
            font-size: 24px;
            vertical-align: -5px;
            color: tint($color2, 50%);
            margin-left: 5px;

            &:hover,
                &.active {
                color: $color2;
            }

            &:not(.active){
                cursor: pointer;
            }

            &.icon-map {
                font-size: 28px;
                vertical-align: -7px;
            }
        }
    }
    
    #maps {
        width: 100%;
        height: 600px;
    }
    
    .infobubble {
        img {
            width: 100%;
        }
    }

    .row.view.view-tiles {
        display: flex;
        flex-wrap: wrap;
        .object_wrapper {
            margin-bottom: 30px;
        }
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {
    #aanbod {
        .sort {
            display: inline;
            line-height: 24px;
            padding: 10px 15px;
        }
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
    #aanbod {
        .sort {
            padding: 20px 15px;
        }
    }
}