#references {
    .reference_wrapper {
        padding: 30px 15px;

        &.even {
            background: #EDEDED;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }
}