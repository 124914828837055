#homepage {
    #waarom-steendijk {
        text-align: center;

        h2 {
            color: #222;
            font-weight: 700;
        }

        .reasons {
            overflow: hidden;

            .reason {
                width: 50%;
                float: left;

                a {
                    text-decoration: none;
                }

                .icon {
                    font-size: 46px;
                    transition: all 300ms ease;
                    color: #7C7D7F;
                }

                &:last-child {
                    width: 100%;
                }

                p {
                    color: #000;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: 14px;
                }

                &:hover {
                    .icon {
                        color: $color1;
                    }
                }
            }
        }
    }

    .wrapper.wrapper-blue {
        h2 {
            font-size: 38px;
            line-height: 1.4;
        }

        &.rating-wrapper {
            position: relative;
            padding: 50px 0;

            h2 {
                font-weight: normal;
                font-size: 24px;
                text-transform: inherit;
            }

            .review {
                position: relative;
                margin-top: 30px;
                padding: 30px 15px;
                background: #fff;
                border-radius: 5px;
                color: #000;
                font-size: 14px;
                text-align: center;

                p {
                    margin: 0;

                    span {
                        color: $color1;
                        font-weight: bold;
                    }
                }

                &:before,
                &:after {
                    color: #f2f2f2;
                    // font-size: 70px;
                    font-size: 30px;
                    position: absolute;
                    font-family: 'icomoon';
                    // z-index: -1;
                }

                &:before {
                    content: '\e923';
                    top: -10px;
                    left: 10px;
                    // top: -25px;
                    // left: 20px;
                }

                &:after {
                    content: '\e922';
                    // bottom: -20px;
                    // right: 20px;
                    bottom: -7px;
                    right: 10px;
                }
            }
        }
    }

    .foto_wrapper {
        position: relative;
        background: url('/img/homepage-rechts.jpg') left bottom no-repeat;
        background-size: contain;
    }

    #aanbodswiper{
        overflow: hidden;
        .swiper-container{
            padding: 0 0 30px 0;
        }
    }

    .wrapper.text-content {
        .video {
            background: #fff;
            border-radius: 5px;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
            overflow: hidden;

            .video_container {
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;

                .play-button {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 80px;
                    height: 80px;
                    background: #FFFFFF66;
                    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.26);
                    border-radius: 50%;
                    display: block;
                    transition: all 0.3s;

                    img {
                        width: 15px;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }

                    &:hover {
                        background: $color1;
                        cursor: pointer;
                    }
                }

                img {
                    position: absolute;
                    object-fit: cover;

                    &.background {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .text {
                padding: 15px 0;

                p {
                    margin: 0;
                    color: $color1;
                    font-weight: bold;
                }
            }
        }
    }

    .wrapper.wrapper-waardebepaling {
        background: url('/img/waardebepaling.png') no-repeat center center;
        background-size: cover;
        text-align: center;
        padding: 100px 0;

        h2 {
            font-size: 34px;
            color: #fff;
        }

        .btn.btn-grey {
            background: #7B7B7D;
            font-size: 14px;
            font-weight: bold;
        }
    }

    .wrapper.wrapper-newsletter {
        padding: 70px 0;
        text-align: center;
    }
}

@media (min-width: $screen-sm-min) {
    #homepage {
        #waarom-steendijk {
            h2 {
                font-size: 34px;
                margin-bottom: 30px;
            }

            .reasons {
                .reason {
                    width: 33.33%;

                    .icon {
                        font-size: 60px;
                    }

                    &:nth-child(4),
                    &:last-child {
                        width: 50%;
                    }
                }
            }
        }

        .wrapper.wrapper-blue {
            padding-bottom: 0;

            &.rating-wrapper {
                padding: 80px 0;

                .review {
                    position: relative;
                    margin-top: 0;
                    z-index: 1;
                }

                img {
                    position: absolute;
                    z-index: 0;
                    bottom: 0;
                    height: 85%;
                    left: 55%;
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #homepage {
        #waarom-steendijk {
            .reasons {
                .reason {
                    width: 20%;

                    &:nth-child(4),
                    &:last-child {
                        width: 20%;
                    }
                }
            }
        }

        .wrapper.wrapper-blue {
            &.rating-wrapper {
                padding: 100px 0;

                h2 {
                    font-size: 34px;
                }

                .review {
                    position: relative;
                    z-index: 1;
                    margin-top: 0;
                    padding: 50px 30px;

                    &:before,
                    &:after {
                        font-size: 50px;
                    }

                    &:before {
                        top: -17px;
                        left: 15px;
                    }

                    &:after {
                        bottom: -15px;
                        right: 15px;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    #homepage {
        .wrapper.wrapper-blue {
            &.rating-wrapper {
                h2 {
                    font-size: 36px;
                }

                .review {
                    padding: 70px 45px;

                    p {
                        font-size: 20px;
                        line-height: 1.5;
                    }

                    &:before,
                    &:after {
                        font-size: 70px;
                    }

                    &:before {
                        top: -25px;
                        left: 15px;
                    }

                    &:after {
                        bottom: -25px;
                        right: 15px;
                    }
                }
            }
        }
    }
}