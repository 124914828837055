.article {
    margin-bottom: 30px;

    a {
        text-decoration: none;

        figure {
            position: relative;
            margin-bottom: 15px;

            img {
                display: block;
                width: 100%;
                border: 1px solid #EEE;
            }

            span {
                display: block;
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,0.4);
                transition: all 0.2s;

                i {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    color: #FFF;
                    font-size: 36px;
                }

            }
        }

        h3 {
            font-weight: bold !important;
            font-size: 16px !important;
            margin: 0 0 5px 0 !important;
            color: #000;
            transition: all 0.2s;
        }

        &:hover {
            text-decoration: none;

            figure {
                span {
                    opacity: 1;
                }
            }

            h3 {
                color: $color1;
            }
        }
    }
}