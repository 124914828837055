header {
    position: relative;

    figure {
        height: 100%;
        width: 100%;

        .video_container {
            height: 80vh;
            overflow: hidden;
            padding: 0;
            position: relative;

            video {
                box-sizing: border-box;
                height: 56.25vw;
                //width: 100%;
                left: 50%;
                min-height: 100%;
                min-width: 100%;
                transform: translate(-50%, -50%);
                position: absolute;
                top: 50%;
                width: 177.77777778vh;
            }
        }
    }

    &.vervolg {
        position: relative;
        overflow: hidden;

        #hero {
            margin-top: 80px;
            height: 220px;
            background: $color1 !important;

            &:before {
                content: ' ';
                position: absolute;
                background: url('/img/review-logo.svg') no-repeat center center;
                top: -130px;
                right: 15%;
                height: 650px;
                width: 650px;
            }

            .payoff {
                h1 {
                    font-size: 30px;
                    font-family: Michroma, sans-serif;
                    color: #fff;
                    background: none;
                    font-weight: 300;
                    margin-bottom: 0;
                }
            }
        }

        .image-wrapper {
            img {
                margin-top: 80px;
                display: block;
                width: 100%;
            }
        }
    }

    &.objectheader {
        height: 70px;
        min-height: 70px;
        background: #000 !important;
    }


    .nav_wrapper {
        transition: all 0.2s;

        .container-fluid {
            position: relative;

            .logo {
                position: relative;
                z-index: 11;
                display: block;
                height: 80px;
                margin: 0;
                border-radius: 5px;
            }

            .button_wrapper {
                position: absolute;
                top: 17px;
                right: 15px;
                z-index: 11;


                ul {
                    display: block;
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    margin-top: 10px;

                    li {
                        display: inline-block;



                        &.round,
                        &.nav-button {
                            a {
                                display: block;
                                width: 36px;
                                height: 36px;
                                border: 1px solid #CCC;
                                line-height: 36px;
                                border-radius: 18px;
                                color: #FFF;
                                text-decoration: none;
                                text-align: center;
                                transition: all 0.2s;

                                span {
                                    line-height: 34px;
                                }

                                &:hover {
                                    background: #FFF;
                                    color: #000;
                                    border-color: #FFF;
                                }
                            }
                        }

                        &.nav-button {
                            margin-left: 15px;

                            a {
                                border: 1px solid #FFF;
                                background: #FFF;
                                color: #000;
                                font-size: 24px;
                            }
                        }

                        a.btn {
                            background: $color4;
                            transform: translateY(-15px);
                            font-weight: bold;
                            text-transform: none;
                            border-radius: 5px;
                            padding: 10px 20px;
                            text-transform: uppercase;
                            font-size: 14px;
                        }
                    }
                }
            }

            nav {
                position: absolute;
                right: 0;
                top: 0;
                width: 100%;
                max-width: 100vw;
                display: none;
                z-index: 10;
                overflow: hidden;
                background: $color1;
                padding-top: 90px;


                ul {
                    list-style: none;
                    padding: 0;
                    margin: 0;
                    border-bottom: 1px solid #999;

                    li {
                        border-top: 1px solid rgba(153, 153, 153, 0.4);

                        a {
                            display: block;
                            color: #FFF;
                            text-decoration: none;
                            text-transform: uppercase;
                            line-height: 30px;
                            padding: 10px 15px;
                            user-select: none;
                            font-weight: bold;
                            font-size: 14px;

                            &:hover,
                            &.active {
                                background: rgba(0, 0, 0, 0.2);
                            }

                            &.active {
                                font-weight: bold;
                            }

                            span {
                                display: block;
                                font-size: 12px;
                                float: right;
                                line-height: 30px !important;
                                transform: rotate(180deg);
                                transition: all 0.2s;

                                &.toggle {
                                    transform: rotate(0deg);
                                }
                            }
                        }

                        > ul {
                            display: none;

                            > li {
                                a {
                                    white-space: nowrap;
                                    padding-left: 25px;
                                    font-weight: bold;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.fixed {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 10;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

            .container-fluid {
                .button_wrapper {
                    ul {
                        li {
                            &.round {
                                a {
                                    color: #333;
                                }
                            }
                        }
                    }
                }

                nav {
                    ul {
                        li {
                            a {
                                color: #fff;
                            }

                            ul {
                                li {
                                    a {
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .payoff {
        position: absolute;
        top: calc(50% + 35px);
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 80%;
        max-width: 800px;

        h2 {
            color: #fff;
            background: rgba(red($color1), green($color1), blue($color1), 0.8);
            padding: 30px 0;
            margin-bottom: 15px;
            text-transform: unset;
            font-family: $font2;
            border-radius: 5px;
        }

        .buttons {
            margin: 0;
            border-radius: 5px;
            overflow: hidden;

            .col-xs-12 {
                padding: 0 !important;

                .button {
                    background: #FFF;
                    color: #000;
                    text-decoration: none;
                    display: block;
                    padding: 10px 0;
                    text-transform: uppercase;
                    font-size: 14px;
                    font-weight: 600;
                    transition: all 300ms ease;

                    &:hover {
                        background: $color1;
                        color: #FFF;
                    }
                }
            }
        }
    }
}

// *****************************************************************
// SM
// *****************************************************************
@media (min-width: $screen-sm-min) {
    header {

        .payoff {
            h2 {
                font-size: 36px;
            }

            .buttons {
                margin: 0;

                .col-xs-12 {
                    padding: 0 !important;

                    .button {
                        background: #FFF;
                        color: #000;
                        text-decoration: none;
                        display: block;
                        padding: 10px 0;
                        text-transform: uppercase;
                        font-weight: 600;
                        transition: all 300ms ease;

                        &:hover {
                            background: $color1;
                            color: #FFF;
                        }
                    }
                }
            }
        }
    }
}

// *****************************************************************
// MD
// *****************************************************************
@media (min-width: $screen-md-min) {
    header {
        .nav_wrapper {
            min-height: 90px;

            .container-fluid {
                nav {
                    display: block !important;
                    padding: 0;
                    top: 22px;
                    right: 50%;
                    transform: translate(50%, 0);
                    width: auto;
                    overflow: visible;
                    background: none;
                    transition: all 0.2s;

                    > ul {
                        border-bottom: none;
                        white-space: nowrap;
                        margin-left: 180px;

                        > li {
                            border-top: none;
                            position: relative;
                            display: inline-block;
                            margin: 0 8px;

                            a {
                                padding: 0px 10px;
                                font-size: 16px;
                                font-weight: 600;

                                span {
                                    display: none;
                                }

                                &:after {
                                    position: absolute;
                                    left: 50%;
                                    bottom: 0;
                                    display: block;
                                    content: '';
                                    width: 0;
                                    height: 1px;
                                    background: #FFF;
                                    transition: all 0.2s;
                                }
                            }

                            &:hover > a,
                            > a.active {
                                position: relative;
                                background: none;

                                &:after {
                                    left: 10px;
                                    width: calc(100% - 20px);
                                }
                            }

                            > ul {
                                position: absolute;
                                top: 100%;
                                left: 10px;
                                background: $color1;
                                text-align: left;
                                display: block;
                                z-index: 10;
                                overflow: hidden;
                                transition: all 0.3s;
                                transform: translate(0, 15px);
                                opacity: 0;
                                visibility: hidden;
                                border: 0;
                                border-radius: 5px;

                                > li {
                                    display: block;
                                    border-top: none;

                                    a {
                                        padding: 5px 15px;
                                        text-transform: none;
                                        font-weight: normal;

                                        &.active {
                                            background: rgba(0, 0, 0, 0.2);
                                        }
                                    }
                                }
                            }

                            &:hover {
                                ul {
                                    transform: none;
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                }

                .button_wrapper {
                    transition: all 0.2s;
                }
            }

            &.fixed {
                .container-fluid {
                    nav {
                        ul {
                            li {
                                a {
                                    color: #333;
                                }

                                ul {
                                    li {
                                        a {
                                            color: #fff;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .payoff {
            max-width: 600px;

            h2 {
                font-size: 40px;
            }
        }

        &.vervolg {
            #hero {
                margin-top: 90px;
                height: 300px;
            }

            .image-wrapper {
                img {
                    margin-top: 90px;
                }
            }
        }
    }
}

// *****************************************************************
// LG
// *****************************************************************
@media (min-width: $screen-lg-min) {
    header {
        &.objectheader {
            background: #000 !important;

            .nav_wrapper.fixed {
                .container-fluid {
                    .logo {
                        height: 80px;
                    }
                }
            }
        }

        .nav_wrapper {
            min-height: 90px;

            .container-fluid {
                .logo {
                    height: 80px;
                    margin: 15px 0;
                    transition: all 0.2s;
                }

                nav {
                    top: 40px;

                    > ul {
                        margin-left: 0px;

                        > li {
                            a {
                                font-size: 15px;
                                padding: 0px 6px;
                            }

                            &:hover > a,
                            > a.active {
                                &:after {
                                    left: 10px;
                                    width: calc(100% - 20px);
                                }
                            }
                        }
                    }
                }

                .button_wrapper {
                    top: 36px;
                }
            }

            &.fixed {
                .container-fluid {
                    position: relative;

                    .logo {
                        position: absolute;
                        height: 180px;
                        margin: 3px 0;
                    }

                    nav {
                        top: 27px;
                    }

                    .button_wrapper {
                        top: 27px;
                    }
                }

                &.scrolled {
                    .container-fluid {
                        .logo {
                            height: 80px;
                        }
                    }
                }
            }
        }

        .payoff {
            top: calc(60% + 15px);
            max-width: 800px;

            h2 {
                font-size: 44px;
            }

            .buttons {
                .col-xs-12 {
                    .button {
                        padding: 20px 10px;
                    }

                    width: 22.5% !important;

                    &:last-child {
                        width: 32.5% !important;
                    }
                }
            }
        }
    }
}