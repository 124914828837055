.btn {
    display: inline-block;
    background: #000;
    color: #FFF;
    padding: 15px 30px;
    font-size: 16px;
    text-decoration: none;
    white-space: nowrap;
    border: none;
    transition: all 200ms ease;
    text-transform: uppercase;
    border-radius: 3px;

    
    &.btn-line {
        background: none;
        border: 1px solid #CCC;
    }

    &.btn-green {
        background: $color4;
        font-weight: bold;
        border-radius: 5px;
        padding: 10px 20px;
        font-size: 14px;

        i{
            margin-left: 5px;
        }

        &:hover {
            background: #0c8548;
        }
    }

    &.btn-blue {
        background: $color1;
        font-weight: bold;
        border-radius: 5px;
        padding: 10px 20px;
        font-size: 14px;

        &:hover {
            background: #0a3766;
        }

    }

    &.btn-100 {
        width: 100%;
        text-align: center;
    }
}


// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {
    .btn {
        padding: 10px 8px;
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
    
    #search {
        .form-group {
            width: 14.28%;
            margin-bottom: 0px;
            
            .form-control {
                padding: 10px 15px;
                height: 42px;
            }
        }
    }
}