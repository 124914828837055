#tekst {
    .video-wrapper {
        position: relative;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
        margin-bottom: 20px;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    img{
        border-radius:5px;
    }

    #huisverkopen {
        .blocks {
            margin-bottom: 30px;

            .block {
                &.text {
                    position: relative;
                    padding-bottom: 50px;
                    overflow: hidden;
                    transition: all 300ms ease;

                    .readmore {
                        position: absolute;
                        bottom: 0;
                        padding: 15px 0;
                        background: white;
                        width: 100%;
                    }
                }
            }
        }
    }

    .banners {
        .banner {
            display: block;

            img {
                width: 100%;
            }
        }
    }

    .table-wrapper {
        overflow: auto;
        margin-bottom:20px;

        table {
            border-radius: 5px;
            overflow: hidden;
            min-width: 1200px;

            thead {
                tr {
                    th {
                        padding: 5px 15px;
                        background: $color1;
                        color: #FFF;
                        text-align: left;

                        &:not(:last-child) {
                            border-right: 1px solid rgba(0, 0, 0, 0.1);
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        padding: 5px 35px;
                        background: #fff;
                        font-size: 14px;
                        border-top: 1px solid rgba(0, 0, 0, 0.1);
                        vertical-align: top;
                        text-align: left;
                        padding-left: 15px;

                        &.price {
                            background: #F0F0F0;
                            font-size: 18px;
                        }

                        &:not(:last-child) {
                            border-right: 1px solid rgba(0, 0, 0, 0.1);
                        }

                        span {
                            position: relative;
                            display: inline-block;
                            padding-left: 15px;
                            text-align: left;

                            &:before {
                                position: absolute;
                                left: 0;
                                top: 8px;
                                content: '';
                                width: 8px;
                                height: 8px;
                                background: $color1;
                                border-radius: 4px;
                            }
                        }
                    }
                }
            }

            tfoot {
                tr {
                    td {
                        padding: 10px 15px;
                        border-top: 1px solid #ccc;
                        background-color: #0d4580;
                        color: #fff;
                        text-align: left;

                        &:not(:last-child) {
                            border-right: 1px solid rgba(0, 0, 0, 0.1);
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #tekst {
        .table-wrapper {
            table {
                min-width: 100%;
            }
        }
    }
}