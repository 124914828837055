#huisVerkopen {

    #frmcontact{
        .g-recaptcha{
            div{
                height: 100%!important;
            }
        }
    }

    #waaromSteendijk {
        //margin-top: 30px;

        ul {
            margin: 30px 0 0 0;
            padding: 0;
            list-style: none;

            li {
                text-align: center;

                i {
                    display: inline-block;
                    color: #797A7C;
                    font-size: 66px;
                    margin-bottom: 30px;
                }
            }
        }
    }

    .rating-wrapper {
        position: relative;
        padding: 50px 0;

        h2 {
            font-weight: normal;
            font-size: 24px;
            text-transform: inherit;
        }

        .review {
            position: relative;
            margin-top: 30px;
            padding: 30px 15px;
            background: #fff;
            border-radius: 5px;
            color: #000;
            font-size: 14px;
            text-align: center;

            p {
                margin: 0;

                span {
                    color: $color1;
                    font-weight: bold;
                }
            }

            &:before,
            &:after {
                color: #f2f2f2;
                // font-size: 70px;
                font-size: 30px;
                position: absolute;
                font-family: 'icomoon';
                // z-index: -1;
            }

            &:before {
                content: '\e923';
                top: -10px;
                left: 10px;
                // top: -25px;
                // left: 20px;
            }

            &:after {
                content: '\e922';
                // bottom: -20px;
                // right: 20px;
                bottom: -7px;
                right: 10px;
            }
        }
    }

    #contacts {
        padding-top: 0px;
        .contact {
            position: relative;
            background: #FFF;
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            border-radius: 5px;

            img {
                height: 100%;
                width: 35%;
                align-self: center;
            }

            .contact-content {
                padding: 15px;
                flex: 1;

                h2 {
                    text-transform: none;
                    font-size: 20px;
                    margin-bottom: 10px;
                }

                h3 {
                    text-transform: none;
                    font-size: 18px;
                    color: #7C7D7F;
                }

                a {
                    display: inline-block;
                    text-decoration: none;
                    margin-top: 30px;
                    font-weight: bold;
                    text-transform: uppercase;
                    font-size: 16px;

                    i {
                        display: inline-block;
                        margin-left: 6px;
                    }
                }
            }
        }
    }

    #imageWrapper {
        height: 300px;
        background: url('/img/footer.jpg') center center no-repeat, transparent linear-gradient(180deg, rgba(13, 69, 128, 0) 0%, rgba(13, 69, 128, 1) 100%) 0% 0% no-repeat padding-box;
        background-size: cover;
        background-blend-mode: multiply;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        h2 {
            font-family: "Michroma", sans-serif;
            font-size: 30px;
            color: #FFF;
            font-weight: 400;
            letter-spacing: 2px;
        }
    }
}

// *****************************************************************
// SM
// *****************************************************************

@media (min-width: $screen-sm-min) {
    #huisVerkopen {
        .rating-wrapper {
            padding: 80px 0;

            .review {
                position: relative;
                margin-top: 0;
                z-index: 1;
            }

            img {
                position: absolute;
                z-index: 0;
                bottom: 0;
                height: 85%;
                left: 55%;
            }
        }
    }
}

// *****************************************************************
// MD
// *****************************************************************

@media (min-width: $screen-md-min) {
    #huisVerkopen {
        #waaromSteendijk {
            //margin-top: 50px;
            padding-bottom: 40px;

            ul {
                display: flex;
                margin-top: 50px;

                li {
                    width: 20%;
                }
            }
        }

        .rating-wrapper {
            padding: 100px 0;

            h2 {
                font-size: 34px;
            }

            .review {
                position: relative;
                z-index: 1;
                margin-top: 0;
                padding: 50px 30px;

                &:before,
                &:after {
                    font-size: 50px;
                }

                &:before {
                    top: -17px;
                    left: 15px;
                }

                &:after {
                    bottom: -15px;
                    right: 15px;
                }
            }
        }

        #contacts {
            .contact {
                img {
                    width: 40%;
                }

                .contact-content {
                    padding: 30px;
                }
            }
        }
    }
}

// *****************************************************************
// LG
// *****************************************************************

@media (min-width: $screen-lg-min) {
    #huisVerkopen {
        #waaromSteendijk {
            //margin-top: 60px;

            ul {
                margin-top: 60px;
            }
        }

        .rating-wrapper {
            position: relative;

            h2 {
                font-size: 36px;
            }

            .review {
                padding: 70px 45px;

                p {
                    font-size: 20px;
                    line-height: 1.5;
                }

                &:before,
                &:after {
                    font-size: 70px;
                }

                &:before {
                    top: -25px;
                    left: 15px;
                }

                &:after {
                    bottom: -25px;
                    right: 15px;
                }
            }
        }

        #imageWrapper {
            height: 480px;
            h2{
                font-size: 40px;
            }
        }
    }
}