.form-control {
    height: 48px;
    box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.16);
    border: 1px solid #ccc;
    border-radius: 5px;
    opacity: 1;
}

.checkbox label {
    font-size: 14px;
}