aside {
    .logo-aside {
        width: 100%;
        margin-bottom: 30px;
    }

    .contact {
        background: #ededed;
        padding: 15px;
        border-radius: 5px;

        form {
            overflow: hidden;
        }
    }
}