.search-wrapper {
    background: #F5F5F5;

    #search {
        display: none;
        position: absolute;
        left: 0px;
        top: 0px;
        z-index: 10;
        width: 100%;
        transform: all 0.3s;
        padding: 15px 0 0 0;

        &:after {
            content: " ";
            width: 100%;
            display: block
        }

        form {
            @extend %clearfix;

            .btn {
                border: none;
                background: #000;
                color: #FFF;
                width: 100%;
            }

            .form-group {
                @extend .col-xs-12;

                .control-label {
                    display: none;
                }

                .form-control {
                    padding: 10px 15px;
                    height: 42px;
                }
            }
        }
    }
}

// *****************************************************************
// SM
// *****************************************************************

@media (min-width: $screen-sm-min) {
    .search-wrapper {
        #search {
            display: block !important;
            position: static;
            padding: 30px 0;

            .search-wrapper.wrapper-gray & {
                background: #F5F5F5;
            }

            form {
                .form-group {
                    width: 33.333333%;
                }

                > .form-group:last-of-type {
                    margin-left: 66.666666%;
                }
            }
        }
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
    .search-wrapper {
        #search {
            form {
                .form-group {
                    width: 21.00%;
                    margin-bottom: 0px;
                }

                > .form-group:last-of-type {
                    margin-left: 0;
                    width: 10%;
                }
            }
        }
    }
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {

    .slideshow_wrapper {
        .search-wrapper.floating {
            position: absolute;
            bottom: 60px;
            left: 50%;
            width: 100%;
            transform: translate(-50%, 0);

            #search {
                background: rgba(255, 255, 255, 0.75);
            }
        }
    }

    #search {
        form {
            .form-group {
                .form-control {
                    padding: 10px 15px;
                    height: 42px;
                }
            }
        }
    }
}