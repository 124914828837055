a.property {
    position: relative;
    display: block;
    text-align: center;
    color: #000 !important;
    text-decoration: none;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    border-radius: 5px;
    overflow: hidden;

    &:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
    }

    figure {
        .label {
            position: absolute;
            left: 20px;
            top: 20px;
            background: $color1;
            color: #FFF;
            line-height: 34px;
            font-size: 14px;
            padding: 0 15px;
            font-weight: 600;
            text-transform: uppercase;
            border-radius: 5px;
        }

        img {
            display: block;
            width: 100%;
        }

        figcaption {
            padding: 15px 0 0 0;
            background: #FFF;

            h3 {
                text-transform: none;
                font-size: 18px;
                padding-bottom: 0;
                border-bottom: none;
                margin-bottom: 5px;
                color: $color1;
            }

            h4 {
                color: #000;
                font-size: 16px;
                font-weight: normal;
                margin-bottom: 0;
                text-transform: uppercase;
            }

            .footer {
                display: flex;
                align-items: center;
                justify-content: center;
                border-top: 1px solid #EEE;
                color: $color1;
                margin-top: 20px;

                small {
                    font-size: 10px;
                    color: #000;
                    text-transform: uppercase;
                }

                span {
                    flex-grow: 1;
                    padding: 10px;
                    border-right: 1px solid #EEE;
                    font-size: 14px;

                    &:last-child {
                        border-right: 0;
                    }

                    i {
                        margin-right: 5px;
                        color: $color1;
                    }
                }
            }
        }
    }
}

// *****************************************************************
// MD
// *****************************************************************

@media (min-width: $screen-md-min) {
    a.property {
    }
}