#packages {
    padding: 30px 0;

    strong {
        color: $color1;
    }

    .table {
        padding: 0 15px;

        .header,
        .line {
            padding-top: 15px;
            padding-bottom: 15px;
        }

        .header {
            background: #0c447f;
            color: #fff;
            font-weight: bold;
            border-radius: 5px;
        }

        .footer{
            background: #0c447f;
            color: #fff;
            font-weight: bold;
            border-radius: 5px;
            padding: 15px;
        }

        .line {
            border-radius: 5px;
            &:nth-child(even) {
                background: #EDEDED;
            }

            .icon-check {
                color: $color4;
            }

            .title {
                font-weight: bold;
            }
        }
    }

    aside {
        img {
            width: 100%;
        }
    }
}

@media (min-width: $screen-sm-min) {
    #packages {
        padding: 50px 0;

        .table {
            .line {
                &:nth-child(odd) {
                    background: #EDEDED;
                }
                &:nth-child(even) {
                    background: #fff;
                }

                .icon-check {
                    font-size: 23px;
                }
            }
        }

        aside {
            a {
                img {
                    width: calc(50% - 7.5px);
                    float: left;
                }
                
                &:first-child {
                    img {
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #packages {
        padding: 100px 0;

        aside {
            a {
                img {
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 30px;
                }
            }
        }
    }
}