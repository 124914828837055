.pagination {
    list-style: none;

    li {
        font-family: $font1;
        font-weight: bold;
        font-size: 20px;
        display: inline-block;
        width: 30px;
        border-radius: 4px;

        a {
            color: #5b5b5b;
            text-decoration: none;

            &:hover {
                color: $color1;
            }
        }

        &.disabled {
            color: #AAA;
        }

        &.active {
            background: $color1;
            color: #FFF;
            margin: 0 10px;
        }

        &:first-child {
            &:after {
                content: " ";
                display: block;
            }
        }
    }
}