a.property-aanmelding {
    position: relative;
    color: #000 !important;
    text-decoration: none;

    figure {
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
        transition: all 0.3s;

        &:hover {
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
        }

        .label {
            position: absolute;
            left: 15px;
            top: 15px;
            background: $color1;
            color: #FFF;
            line-height: 34px;
            font-size: 14px;
            padding: 0 15px;
            font-weight: 600;
            text-transform: uppercase;
            border-radius: 5px;
        }

        .image-wrapper {
            display: flex;

            img {
                display: block;
                width: 40%;
            }

            figcaption {
                background: #FFF;
                display: flex;
                flex: 1;
                flex-direction: column;
                justify-content: space-between;

                .content {
                    padding: 15px;

                    h3 {
                        text-transform: none;
                        font-size: 18px;
                        padding-bottom: 0;
                        border-bottom: none;
                        margin-bottom: 10px;
                        color: $color1;
                    }

                    p {
                        font-size: 16px;
                        line-height: 1.5;
                    }
                }

                .footer {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-top: 1px solid #EEE;
                    color: $color1;
                    margin-top: 20px;
                    text-align: center;

                    small {
                        font-size: 10px;
                        color: #000;
                        text-transform: uppercase;
                    }

                    span {
                        flex-grow: 1;
                        padding: 10px;
                        border-right: 1px solid #EEE;
                        font-size: 14px;

                        &:last-child {
                            border-right: 0;
                        }

                        i {
                            margin-right: 5px;
                            color: $color1;
                        }
                    }
                }
            }
        }
    }
}

// *****************************************************************
// MD
// *****************************************************************

@media (min-width: $screen-md-min) {
    a.property {
    }
}