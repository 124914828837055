@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?7fxm15');
  src:  url('fonts/icomoon.eot?7fxm15#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?7fxm15') format('truetype'),
  url('fonts/icomoon.woff?7fxm15') format('woff'),
  url('fonts/icomoon.svg?7fxm15#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-deal:before {
  content: "\e924";
}
.icon-location:before {
  content: "\e925";
}
.icon-aandacht:before {
  content: "\e91f";
}
.icon-graden:before {
  content: "\e90d";
}
.icon-brochure:before {
  content: "\e915";
}
.icon-fotografie:before {
  content: "\e917";
}
.icon-verkoopstrategie:before {
  content: "\e918";
}
.icon-nrvt:before {
  content: "\e916";
}
.icon-video:before {
  content: "\e913";
}
.icon-360:before {
  content: "\e914";
}
.icon-details:before {
  content: "\e909";
}
.icon-fotos:before {
  content: "\e90a";
}
.icon-locatie:before {
  content: "\e90b";
}
.icon-omschrijving:before {
  content: "\e90c";
}
.icon-plattegrond:before {
  content: "\e90f";
}
.icon-streetview:before {
  content: "\e911";
}
.icon-zonnestand:before {
  content: "\e912";
}
.icon-facebook:before {
  content: "\e901";
}
.icon-instagram:before {
  content: "\e902";
}
.icon-mail:before {
  content: "\e905";
}
.icon-phone:before {
  content: "\e904";
}
.icon-search:before {
  content: "\e906";
}
.icon-nvm:before {
  content: "\e903";
}
.icon-funda:before {
  content: "\e939";
}
.icon-scvm:before {
  content: "\e90e";
}
.icon-nwwi:before {
  content: "\e629";
}
.icon-vastgoedcert:before {
  content: "\e62c";
}
.icon-erkend-taxateur:before {
  content: "\e919";
}
.icon-door-open:before {
  content: "\e926";
}
.icon-chevron-right:before {
  content: "\e910";
}
.icon-chevron-down:before {
  content: "\e91e";
}
.icon-menu:before {
  content: "\e900";
}
.icon-quotes-right:before {
  content: "\e922";
}
.icon-rdquo:before {
  content: "\e922";
}
.icon-quotes-left:before {
  content: "\e923";
}
.icon-ldquo:before {
  content: "\e923";
}
.icon-linkedin:before {
  content: "\e920";
}
.icon-check:before {
  content: "\e921";
}
.icon-arrow-right:before {
  content: "\e91a";
}
.icon-arrow-left:before {
  content: "\e91b";
}
.icon-arrow-down:before {
  content: "\e91c";
}
.icon-arrow-up:before {
  content: "\e91d";
}
.icon-inhoud:before {
  content: "\f1b2";
}
.icon-bed:before {
  content: "\f236";
}
.icon-perceel:before {
  content: "\f278";
}
.icon-angle-up:before {
  content: "\e907";
}
.icon-angle-left:before {
  content: "\e908";
}
.icon-tag:before {
  content: "\e927";
}
